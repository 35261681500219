import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import VideoList from './components/VideoList';
import VideoDetail from './components/VideoDetail';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute element={VideoList} />} />
        <Route path="/videos" element={<PrivateRoute element={VideoList} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/video/:id" element={<PrivateRoute element={VideoDetail} />} />
      </Routes>
    </Router>
  );
};

export default App;
