import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../globalStyles.css";
import { adminLogin } from "../apiService";
//import myImage from '../../public/piramal-finance.svg';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Call the adminLogin function with email and password
      await adminLogin(email, password);

      // If the login is successful, navigate to the videos page
      navigate("/videos");
    } catch (error) {
      // If there's an error, set an error message
      setError("Invalid email or password. Please try again.");
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="login-container">
      <section className="fit" >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-5">
              <div className="card p-5 rounded-4 ">
                <div className="text-center mb-5" >
                  <img src="/piramal-finance.svg" alt="Piramal Finance" className="main_logo" />
                </div>
                <h1 className="fs-5 text-uppercase fw-semibold text-center text-dark mb-4">Admin Login</h1>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <div className="row ">
                  <div className="col-sm-12 mb-2">
                    <label for="emailID" class="form-label">Email address</label>
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      id="emailID"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ marginBottom: "10px" }}
                    />
                  </div>
                  <div className="col-sm-12 mb-4">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" id="inputPassword5" placeholder="Enter Password" class="form-control form-control-lg" aria-describedby="passwordHelpBlock" onChange={(e) => setPassword(e.target.value)} value={password} />

                  </div>
                  <div className="col-sm-12 text-center">
                    <button className="btn btn-primary" onClick={handleLogin}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
