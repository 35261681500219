import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchVideos, addVideo, exportVideos, deleteVideoById } from '../apiService';
import '../globalStyles.css';
import { FaPlay, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaTwitter, FaTrashAlt, FaTrash } from 'react-icons/fa';

import { MdGridView, MdViewList } from "react-icons/md";


const VideoList = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [videos, setVideos] = useState([]);
    const [view, setView] = useState('list');
    const [showModal, setShowModal] = useState(false);
    const [newVideo, setNewVideo] = useState({ title: '', description: '', youtubeUrl: '', language: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const savedView = localStorage.getItem('view');
        if (savedView) {
            setView(savedView);
        }

        const getVideos = async () => {
            try {
                const videosData = await fetchVideos();
                setVideos(videosData);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        getVideos();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewVideo((prevVideo) => ({ ...prevVideo, [name]: value }));
    };

    const handleAddVideo = async () => {
        setLoading(true);
        try {
            await addVideo(newVideo);
            setShowModal(false);
            setNewVideo({ title: '', description: '', youtubeUrl: '' });
            const videosData = await fetchVideos();
            setVideos(videosData);
        } catch (error) {
            console.error('Error adding video:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteVideo = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm('Are you sure you want to delete this video?')) {
            setLoading(true);
            try {
                await deleteVideoById(id);
                // const videosData = await fetchVideos();
                // setVideos(videosData);
            } catch (error) {
                console.error('Error deleting video:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleExport = async () => {
        try {
            await exportVideos();
        } catch (error) {
            console.error('Error exporting videos:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleViewChange = (newView) => {
        setView(newView);
        localStorage.setItem('view', newView);
    };

    const filteredVideos = videos.filter(video =>
        video.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        video.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sharePlatforms = {
        insta: FaInstagram,
        facebook: FaFacebook,
        linkedin: FaLinkedin,
        whatsapp: FaWhatsapp,
        twitter: FaTwitter,
    };

    return (
        <div className="container">
            {loading && <div className="overlay-loading"><div className="spinner"></div></div>}
            <div className="controls">
                <input
                    type="text"
                    placeholder="Search..."
                    className="search-input"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                <div className="action-btn">
                    <div className="view-toggle">
                        <button onClick={() => handleViewChange('list')} className={view === 'list' ? 'active' : ''}><MdViewList /></button>
                        <button onClick={() => handleViewChange('grid')} className={view === 'grid' ? 'active' : ''}><MdGridView /></button>
                    </div>
                    <button className="export-button" onClick={() => setShowModal(true)}> + Add</button>
                    <button className="export-button" onClick={handleExport}>Export</button>
                </div>
            </div>
            {view === 'list' ? (
                <ul className="video-list">
                    {filteredVideos.map(video => (
                        <li key={video.id} className="video-list-item">
                            <Link to={`/video/${video.id}`} className="video-link">
                                <div className="video-thumbnail-container">
                                    {video.thumbnailUrl.startsWith("http") ? (
                                        <img src={video.thumbnailUrl} alt={video.title} className="video-thumbnail" />
                                    ) : (
                                        <FaPlay className="video-icon" />
                                    )}
                                </div>
                                <div className="video-details">
                                    <p className="video-title">{video.title}</p>
                                    <p className="video-description">{video.description}</p>
                                </div>
                                <div className="p-12">
                                    <h4>Shares</h4>
                                    <ul className="shares-list d-flex gap-20 text-center">
                                        {Object.entries(video.shares).map(([platform, count]) => {
                                            const Icon = sharePlatforms[platform];
                                            return (
                                                <li key={platform} className="d-flex column">
                                                    <span ><Icon /></span>
                                                    <span >{count}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <button className="delete-button" onClick={(e) => handleDeleteVideo(e, video.id)}>
                                    <FaTrash /> Delete
                                </button>

                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="video-grid">
                    {filteredVideos.map(video => (
                        <div key={video.id} className="video-grid-item">
                            <Link to={`/video/${video.id}`} className="video-link">
                                <div className="video-thumbnail-container">
                                    {video.thumbnailUrl.startsWith("http") ? (
                                        <img src={video.thumbnailUrl} alt={video.title} className="video-thumbnail" />
                                    ) : (
                                        <div className="video-icon-div"><FaPlay className="video-icon" /></div>
                                    )}
                                </div>
                                <div className="video-details">
                                    <p className="video-title">{video.title}</p>
                                    <p className="video-description">{video.description}</p>
                                </div>

                                <ul className="shares-list d-flex justify-space-around text-center pt-30">
                                    {Object.entries(video.shares).map(([platform, count]) => {
                                        const Icon = sharePlatforms[platform];
                                        return (
                                            <li key={platform} className="d-flex column">
                                                <span ><Icon /></span>
                                                <span >{count}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <button className="delete-button" onClick={(e) => handleDeleteVideo(e, video.id)}>
                                    <FaTrash /> Delete
                                </button>
                            </Link>

                        </div>
                    ))}
                </div>
            )}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <input
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={newVideo.title}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={newVideo.description}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            name="youtubeUrl"
                            placeholder="YouTube URL"
                            value={newVideo.youtubeUrl}
                            onChange={handleInputChange}
                        />
                        <select
                            name="language"
                            value={newVideo.language}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled>Select Language</option>
                            <option value="all">All</option>
                            <option value="hindi">Hindi</option>
                            <option value="marathi">Marathi</option>
                            <option value="gujarati">Gujarati</option>
                            <option value="kannada">Kannada</option>
                            <option value="telugu">Telugu</option>
                            <option value="tamil">Tamil</option>
                            <option value="malayalam">Malayalam</option>
                            <option value="bengali">Bengali</option>
                        </select>
                        <div className="modal-actions">
                            <button onClick={() => setShowModal(false)} className="modal-button cancel">Cancel</button>
                            <button onClick={handleAddVideo} className="modal-button">Add</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoList;
