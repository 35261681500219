import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchVideoDetails } from '../apiService';
import { FaPlay, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaTwitter, FaEye } from 'react-icons/fa';
import '../globalStyles.css';

const VideoDetail = () => {
    const { id } = useParams();
    const [video, setVideo] = useState(null);

    useEffect(() => {
        const getVideoDetails = async () => {
            try {
                const videoData = await fetchVideoDetails(id);
                setVideo(videoData);
            } catch (error) {
                console.error('Error fetching video details:', error);
            }
        };

        getVideoDetails();
    }, [id]);

    if (!video) {
        return <div className="overlay-loading"><div className="spinner"></div></div>
    }

    const sharePlatforms = {
        insta: FaInstagram,
        facebook: FaFacebook,
        linkedin: FaLinkedin,
        whatsapp: FaWhatsapp,
        twitter: FaTwitter,
    };

    return (
        <div className="video-detail-container">
            <div className="video-info-top-row"> {/* New container for top row */}
                <div className="video-thumbnail-container">
                    {video.video.thumbnailUrl.startsWith("http") ? (
                        <div className="video-thumbnail-stack">
                            <img src={video.video.thumbnailUrl} alt={video.video.title} className="video-thumbnail" />
                            <a href={video.video.youtubeUrl} target="_blank" rel="noopener noreferrer" className="youtube-video-link">
                                <FaPlay className="play-icon" />
                            </a>
                        </div>
                    ) : (
                        <div className='video-icon-div'>
                            <FaPlay className="video-icon" />
                        </div>
                    )}
                </div>
                <div className="video-details-stats-container">
                    <div className="video-details">
                        <div>
                            <p className="video-title">{video.video.title}</p>
                            <p className="video-description">{video.video.description}</p>
                        </div>
                    </div>
                    <div className="video-stats">
                        <div className="views-icon">
                            <FaEye className="icon" />
                            <div>{video.video.views} views</div>
                        </div>
                        <hr className="divider" />
                        <div className="video-shares">
                            <h4>Shares</h4>
                            <ul className="shares-list">
                                {Object.entries(video.video.shares).map(([platform, count]) => {
                                    const Icon = sharePlatforms[platform];
                                    return (
                                        <li key={platform} className="shares-item">
                                            <span className="shares-icon"><Icon /></span>
                                            <span className="shares-platform">{platform}</span>
                                            <span className="shares-count">{count}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    {/* <div className="video-stats" style>
                        <div className="views-icon">
                            <FaEye className="icon" /> <div>{video.video.views} views</div>
                        </div>
                        <div className="video-shares">
                            <h3>Shares</h3>
                            <ul>
                                {Object.entries(video.video.shares).map(([platform, count]) => {
                                    const Icon = sharePlatforms[platform];
                                    return (
                                        <li key={platform} className="interaction-icon">
                                            <span className="shares-icon"><Icon /></span>
                                            <span className="shares-platform">{platform}</span>
                                            <span className="shares-count">{count}</span>
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                    </div> */}
                </div>
            </div>
            <table className="users-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Shares</th>
                        <th>Watched</th>
                    </tr>
                </thead>
                <tbody>
                    {video.users.map((user, index) => (
                        <tr key={user.employeeId}>
                            <td>{user.employeeId}</td>
                            <td>{user.email}</td>
                            <td>
                                {Object.entries(sharePlatforms).map(([platform, Icon]) => (
                                    <button
                                        key={platform}
                                        className={`interaction-button ${user[platform] ? 'active' : ''}`}
                                    >
                                        <Icon />
                                    </button>
                                ))}
                            </td>
                            <td>{user.watched ? 'Yes' : 'No'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default VideoDetail;
